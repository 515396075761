//@ts-nocheck
import LazyLoad from 'vanilla-lazyload';

const initLazyLoad = () => {
    window.lazyLoadInstance = new LazyLoad({
        elements_selector: '.lazy',
    });
};

initLazyLoad();
